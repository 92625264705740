import { render, staticRenderFns } from "./name-deon.html?vue&type=template&id=90487a8c&scoped=true&"
import script from "./name-deon.script.js?vue&type=script&lang=js&"
export * from "./name-deon.script.js?vue&type=script&lang=js&"
import style0 from "./name-deon.scss?vue&type=style&index=0&id=90487a8c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90487a8c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCardActions,VCardText,VChip,VFileInput,VTextField,VTextarea})
